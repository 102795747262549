<!--
 * @Description: 请输入文件描述
 * @Date: 2020-01-10 14:40:58
 * @LastEditTime: 2020-04-02 11:36:48
 * @LastEditors: 前胡
 -->
<template>
  <div id='withdraw'>
<!--    <p class="bread-crumbs">{{route.name}} / {{subRouteName}}</p>-->
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'Withdraw',
    data () {
      return {
        route: null,
        subRouteName: null
      }
    },
    created () {
      // 初次渲染面包屑
      this.renderNav()

      // 监听路由变化，修改顶部面包屑文字
      this.$watch('$route', to => this.subRouteName = to.name)
    },
    methods: {
      renderNav () {
        const route = this.$store.state.user.routes;
        this.route = route.find(item => item.path === '/admin').children.find(item => item.path === 'withdraw')
        const subPath = this.$route.path.split('/')[3]
        this.subRouteName = this.route.children.find(item => item.path === subPath).name
      }
    }
  }
</script>

<style lang='less' scoped>
  #withdraw {
    background: #fff;
    padding: 16px;
    .bread-crumbs {
      font-size: 12px;
      color: #666;
      padding: 8px 10px;
      background: #F0F0F0;
      margin-bottom: 10px;
    }
  }
</style>
